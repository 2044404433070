import React, { useLayoutEffect, useState, useRef } from "react";
import { mount, route } from "navi";
import { Link, useCurrentRoute } from "react-navi";
import { Typography, Card } from "@material-ui/core";
import { Helmet } from "react-navi-helmet-async";
import { OtherToolSelection } from "./Menu";
import i18next from "../i18n";

import favicon from "./assets/favicon.ico";
import faviconDark from "./assets/faviconDark.ico";

function useWindowSize() {
    const [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);
    return size;
}

const cartesianToPolar = (x, y) => {
    const dist = Math.sqrt(x * x + y * y);
    const fie = Math.atan2(y, x);
    return { dist, fie };
};

const polarToCatesian = (dist, fie) => {
    const x = Math.cos(fie) * dist;
    const y = Math.sin(fie) * dist;
    return { x, y };
};

const deg2rad = (deg) => {
    return (deg / 180) * Math.PI;
};

const rad2deg = (rad) => {
    return (rad / Math.PI) * 180;
};

const num2hex = (num) => {
    return "0x" + ("00" + num.toString(16)).substr(-2);
};

const num2oct = (num) => {
    return "0o" + ("000" + num.toString(8)).substr(-3);
};

const num2binary = (num) => {
    return "0b" + ("00000000" + num.toString(2)).substr(-8);
};

const generateLazyPage = (component) => {
    return mount({
        "/": route({
            getView: () => component,
        }),
    });
};

const DescriptionCard = (props) => {
    const divRef = useRef(null);
    const avalibleCardHeight = props.scroll
        ? divRef.current === null
            ? 200
            : window.innerHeight - divRef.current.offsetTop - 56
        : "auto";

    return (
        <div ref={divRef} style={{ paddingBottom: 16 }}>
            <Card raised={true} style={{ margin: props.padding !== undefined ? props.padding : 8, padding: 16 }}>
                <div style={{ height: avalibleCardHeight, overflowY: "auto" }}>
                    {props.children}
                    {props.children !== undefined && <div style={{ height: 16 }} />}
                    {!props.noTools && <OtherToolSelection />}
                </div>
            </Card>
        </div>
    );
};

const SimpleDescriptionContent = (props) => {
    return (
        <div>
            <Typography variant="h5" component="h2">
                {props.text("usageHeadline")}
            </Typography>
            <p>{props.text("usage")}</p>
        </div>
    );
};

const SimpleDescriptionCard = (props) => {
    return (
        <DescriptionCard padding={props.padding} noTools={props.noTools}>
            <SimpleDescriptionContent text={props.text} />
        </DescriptionCard>
    );
};

const SimpleHelmet = (props) => {
    const actualroute = useCurrentRoute();
    const isDarkMode = window.matchMedia && window.matchMedia("(prefers-color-scheme: dark)").matches;
    const title = props.title ? props.title : props.meta("title");
    const description = props.description ? props.description : props.meta("description");
    const keyword = props.keyword ? props.keyword : props.meta("keyword");
    const trailingSlash = actualroute.url.href.slice(-1) === "/" ? "" : "/";
    const url = "https://byte-tools.com" + actualroute.url.href + trailingSlash;

    // eslint-disable-next-line no-unused-vars
    const structuredJSON = {
        "@context": "http://schema.org",
        "@type": "WebApplication",
        name: title,
        url: url,
        description: description,
        applicationCategory: "UtilitiesApplication",
        browserRequirements: "Requires JavaScript. Requires HTML5.",
        softwareVersion: "1.0.0",
        operatingSystem: "All",
        offers: {
            "@type": "Offer",
            price: "0",
        },
        //genre: "tools",
        //applicationSubCategory: "Timezone Converter",
        //permissions: "Location",
        //screenshot: "https://",
        /*about: {
            "@type": "Thing",
            description: "cookies, baking, food",
        },*/
    };

    return (
        <Helmet>
            <title>{title}</title>
            <meta name="description" content={description} />
            <meta name="keyword" content={keyword} />
            {/*<script type="application/ld+json">{JSON.stringify(structuredJSON)}</script>*/}
            <link rel="canonical" href={url} />
            {props.noIndex && <meta name="robots" content="noindex,follow" />}
            {props.custom}
            {isDarkMode ? <link rel="shortcut icon" href={faviconDark} /> : <link rel="shortcut icon" href={favicon} />}
        </Helmet>
    );
};

const linkStyle = (color) => {
    return { color: color !== undefined ? color : "white" };
}; // textDecoration: "none",

const NaviLink = (props) => {
    return (
        <Link
            href={props.href}
            style={linkStyle()}
            className="MuiTypography-root MuiLink-root MuiLink-underlineHover MuiTypography-colorPrimary"
        >
            {props.children}
        </Link>
    );
};

const useLanguageCode = () => {
    return i18next.language || window.localStorage.i18nextLng;
};

const se =
    window.NaviScripts.isBuild || /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i.test(navigator.userAgent);
const isBuild = window.NaviScripts.isBuild;
const getSitename = () => "ByteTool";

export {
    useWindowSize,
    cartesianToPolar,
    polarToCatesian,
    deg2rad,
    rad2deg,
    num2hex,
    num2oct,
    num2binary,
    generateLazyPage,
    DescriptionCard,
    SimpleDescriptionCard,
    SimpleDescriptionContent,
    SimpleHelmet,
    NaviLink,
    useLanguageCode,
    linkStyle,
    se,
    isBuild,
    getSitename,
};
