import register from "navi-scripts/register";
import { createBrowserNavigation } from "navi";
import React from "react";
import ReactDOM from "react-dom";
import { Router } from "react-navi";

import { App } from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import { routes } from "./pages/index";
import { ServerStyleSheets, ThemeProvider } from '@material-ui/core/styles';
import ReactDOMServer from 'react-dom/server';
import theme from './pages/theme';

// `Navi.app()` is responsible for exporting your app's pages and App
// component to the static renderer, and for starting the app with the
// `main()` function when running within a browser.

const getHtmlAndCssFromPage = (navigation, head, helmet) => {
    const sheets = new ServerStyleSheets();
    const html = ReactDOMServer.renderToString(
        sheets.collect(
            React.createElement(ThemeProvider, { theme: theme },
                React.createElement(Router, { navigation },
                    (head && helmet) ?
                        React.createElement(head, { context: helmet, canUseDOM: false }, React.createElement(App)) :
                        React.createElement(App)
                )),
        ),
    );
    //console.log(html);
    const css = sheets.toString().replace(/ {2}|[\t\n\r]/gm, '');
    return { html, css }
}

register({
    // Specify the pages that navi-app should statically build, by passing in a
    // Switch object.
    routes,

    // The default create-react-app renderer needs access to the App component.
    // Learn about custom static renderers at:
    // https://frontarm.com/navi/guides/static-rendering/
    exports: {
        App: App,
        getHtmlAndCssFromPage: getHtmlAndCssFromPage
    },

    // This will only be called when loading your app in the browser. It won't
    // be called when performing static generation.
    async main() {
        let navigation = createBrowserNavigation({ routes, trailingSlash: true });

        // Wait until the navigation has loaded the page's content, or failed to do
        // so. If you want to load other data in parallel while the initial page is
        // loading, make sure to start loading before this line.
        await navigation.getRoute();

        // React requires that you call `ReactDOM.hydrate` if there is statically
        // rendered content in the root element, but prefers us to call
        // `ReactDOM.render` when it is empty.
        let hasStaticContent = process.env.NODE_ENV === "production";
        let renderer = hasStaticContent ? ReactDOM.hydrate : ReactDOM.render;

        // Start react, passing in the current navigation state.
        renderer(
            <Router navigation={navigation}>
                <ThemeProvider theme={theme}>
                    <App />
                </ThemeProvider>
            </Router>,
            document.getElementById("root")
        );

        // If you want your app to work offline and load faster, you can change
        // unregister() to register() below. Note this comes with some pitfalls.
        // Learn more about service workers: http://bit.ly/CRA-PWA
        serviceWorker.register();
    },
});

/*#cookie-bar {
    background: #2b3134 !important;
    box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 1) !important;
}

#cookie-bar-button {
    background-color: #b00000 !important;
}

#cookie-bar-button-no {
    background-color: #2b3134 !important;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 1) !important;
}*/
