import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: "#dd0000", // "#ff6262",
        },
        secondary: {
            main: "#2B3134",
        },
        background: {
            default: "#2B3134",
            paper: "#2B3134",
        },
    },
});

export default theme;