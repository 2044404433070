import { connect, createStore } from "undux";

let store = createStore({
    isMetric: true,
    isFullscreen: false,
    screenSize: 6,
    promptInstall: null,
});

let withStore = connect(store);

let hookStore = (prop) => {
    return [store.get(prop), store.set(prop)];
};

export { store, withStore, hookStore };
