import React from "react";
import { Link, useCurrentRoute } from "react-navi";
import { SwipeableDrawer, Grid, Divider, List, ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { AppBar, Toolbar, Typography, IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";

import { withStore, hookStore } from "./Store";
import { pages } from "./index";
import { useLanguageCode, se, getSitename } from "./Util";
import i18n from "../i18n";

import MenuIcon from "@material-ui/icons/Menu";
import AddToHomeScreenIcon from "@material-ui/icons/AddToHomeScreen";
import ShareIcon from "@material-ui/icons/Share";
import FeedbackIcon from "@material-ui/icons/FeedbackOutlined";
import ImpressumIcon from "@material-ui/icons/AlternateEmail";
import PrivacyIcon from "@material-ui/icons/PolicyOutlined";

const useStyles = makeStyles((theme) => ({
    list: {
        width: 250,
    },
    fullList: {
        width: "auto",
    },
    root: {
        flexGrow: 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    title: {
        flexGrow: 1,
    },
}));

const onRouteChange = (actualRoute) => {
    const langs = ["en", "de"];
    const path = actualRoute.url.href;
    langs.forEach((lang) => {
        if (path.includes(`/${lang}/`)) {
            if (i18n.language !== lang) {
                i18n.changeLanguage(lang);
            }
        }
    });
    for (let route of pages) {
        let url = route.url.slice(-1) === "/" ? route.url.substring(0, route.url.length - 1) : route.url;
        if (path.includes(url) && url !== "") {
            return route;
        }
    }
    return undefined;
};

const OtherToolSelection = () => {
    const { t } = useTranslation(["nav"]);

    let actualroute = useCurrentRoute();
    let page = onRouteChange(actualroute);

    const addPathPrefix = (link) => {
        return t("linkPrefix") + link;
    };

    const otherToolList = pages.filter((x) => x.tool && x.name !== page.name);
    const otherTool = otherToolList.slice(0, Math.min(otherToolList.length, 6));

    return (
        <div>
            <Typography variant="h6">{"Other Tools:"}</Typography>
            <div style={{ height: 16 }} />
            <Grid container direction="row" justify="space-around" alignItems="center">
                {otherTool.map((page) => {
                    const Icon = page.icon;
                    const name =
                        t(page.name + "_short") !== page.name + "_short" ? t(page.name + "_short") : t(page.name);
                    return (
                        <Grid item key={name}>
                            <Link
                                href={addPathPrefix(page.url)}
                                style={{ color: "white", textDecoration: "none" }}
                                key={name}
                            >
                                <Grid
                                    container
                                    direction="column"
                                    justify="center"
                                    alignItems="center"
                                    style={{ padding: 8 }}
                                >
                                    <Grid item>
                                        <Icon fontSize="large" />
                                    </Grid>
                                    <Grid item>
                                        <Typography>{t(name)}</Typography>
                                    </Grid>
                                </Grid>
                            </Link>
                        </Grid>
                    );
                })}
            </Grid>
        </div>
    );
};

const AppbarDrawer = withStore(() => {
    const classes = useStyles();
    const [drawer, setDrawer] = React.useState(false || se);

    const { t } = useTranslation(["nav", "tools"]);
    const lang = useLanguageCode();

    let actualroute = useCurrentRoute();
    //let page = onRouteChange(actualroute);

    const cleanTitle = (str) => str.replace(/ - .+Tool/, "").replace(" - ByteTool", "").replace("AsciiTool", "").replace("ConverterTool", "");

    const [title, setTitle] = React.useState(cleanTitle(document.title));

    React.useEffect(() => {
        const observer = new MutationObserver(function (mutations) {
            if (mutations[0]?.target?.innerText) {
                const updatedTitle = `${mutations[0].target.innerText}`;
                const updatedTitleCleaned = cleanTitle(updatedTitle);
                //const updatedTitleCleaned = updatedTitle.substring(0, updatedTitle.lastIndexOf('-') !== -1 ? updatedTitle.lastIndexOf('-') - 1 : updatedTitle.length);
                setTitle(updatedTitleCleaned);
            }
        })

        observer.observe(
            document.querySelector('title'),
            { subtree: true, characterData: true, childList: true }
        );

        return () => {
            observer.disconnect();
        };
    });

    const toggleDrawer = (open) => (event) => {
        if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
            return;
        }
        setDrawer(open);
    };

    const sharingSupported = navigator.share !== undefined;
    const clickShare = () => {
        if (sharingSupported) {
            navigator.share({
                url: actualroute.url.href,
                title: title,
                //text: "Online ruler to measure real world objects.",
            });
        }
    };

    const addPathPrefix = (link) => {
        return t("linkPrefix") + link;
    };

    let toolComponents = [];

    for (let item of pages) {
        if (item.tool && item.lang.includes(lang)) {
            const Icon = item.icon;
            toolComponents.push(
                <Link href={addPathPrefix(item.url)} style={{ color: "white", textDecoration: "none" }} key={item.name}>
                    <ListItem button>
                        <ListItemIcon>
                            <Icon />
                        </ListItemIcon>
                        <ListItemText primary={t(item.name)} />
                    </ListItem>
                </Link>
            );
        }
    }

    const list = () => (
        <div className={classes} role="presentation" onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
            <ListItem button key={"Toolbox"}>
                <ListItemText
                    primary={
                        <Link href={addPathPrefix("/")} style={{ color: "white", textDecoration: "none" }}>
                            <Typography variant="h2" component="h3" style={{ color: "primary", fontSize: "2.8em" }}>
                                {getSitename()}
                            </Typography>
                        </Link>
                    }
                />
            </ListItem>
            <Divider />
            <List>
                <AddHomescreenButton />
                {sharingSupported && (
                    <ListItem button key="Share" onClick={() => clickShare()}>
                        <ListItemIcon>
                            <ShareIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("share")} />
                    </ListItem>
                )}
                <Link href={addPathPrefix("/feedback/")} style={{ color: "white", textDecoration: "none" }}>
                    <ListItem button key="Feedback">
                        <ListItemIcon>
                            <FeedbackIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("feedback")} />
                    </ListItem>
                </Link>
                <Link href={addPathPrefix("/privacy/")} style={{ color: "white", textDecoration: "none" }}>
                    <ListItem button key="Privacy Policy">
                        <ListItemIcon>
                            <PrivacyIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("privacy")} />
                    </ListItem>
                </Link>
                <Link href={addPathPrefix("/imprint/")} style={{ color: "white", textDecoration: "none" }}>
                    <ListItem button key="Imprint">
                        <ListItemIcon>
                            <ImpressumIcon />
                        </ListItemIcon>
                        <ListItemText primary={t("imprint")} />
                    </ListItem>
                </Link>
            </List>
            <Divider />
            <nav>
                <List>{toolComponents}</List>
            </nav>
            <Divider />
        </div>
    );

    return (
        <div className={classes.root}>
            <AppBar position="static" color="secondary">
                <Toolbar>
                    <IconButton
                        onClick={toggleDrawer(true)}
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="menu"
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" component="h1" className={classes.title} style={{ flexGrow: 1 }}>
                        {title}
                    </Typography>
                    {sharingSupported && (
                        <IconButton color="inherit" onClick={() => clickShare()} aria-label="delete">
                            <ShareIcon />
                        </IconButton>
                    )}
                </Toolbar>
            </AppBar>
            <React.Fragment key="drawer">
                <SwipeableDrawer
                    anchor="left"
                    open={drawer}
                    onClose={toggleDrawer(false)}
                    onOpen={toggleDrawer(true)}
                    style={{ visibility: se ? "hidden" : "auto" }}
                >
                    {list()}
                </SwipeableDrawer>
            </React.Fragment>
        </div>
    );
});

const AddHomescreenButton = withStore(() => {
    const [promptInstall] = hookStore("promptInstall");
    const { t } = useTranslation(["nav"]);

    const onClick = (evt) => {
        evt.preventDefault();
        console.log("we are being triggered install :D");
        if (!promptInstall) {
            return;
        }
        promptInstall.prompt();
    };
    if (promptInstall === null) {
        return null;
    }
    return (
        <ListItem button key="Add to homescreen" onClick={onClick}>
            <ListItemIcon>
                <AddToHomeScreenIcon />
            </ListItemIcon>
            <ListItemText primary={t("addHomeScreen")} />
        </ListItem>
    );
});

export { AppbarDrawer, OtherToolSelection };
