import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./i18n/en.json";
import de from "./i18n/de-DE.json";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
    en: en,
    de: de,
};

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en",
        fallbackLng: ["en"],
        keySeparator: false, // we do not use keys in form messages.welcome
        defaultNS: "translation",
        interpolation: {
            escapeValue: false, // react already safes from xss
        },
    });

export default i18n;
