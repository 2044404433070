import { mount, lazy } from "navi";
import { isDefaultPage } from "./BuildConstant";

import FeedbackIcon from "@material-ui/icons/FeedbackOutlined";
import ImprintIcon from "@material-ui/icons/AlternateEmail";
import PrivacyIcon from "@material-ui/icons/PolicyOutlined";

import RulerIcon from "@material-ui/icons/Straighten";
import BubbleLevelIcon from "@material-ui/icons/BubbleChartOutlined";
import CompassIcon from "@material-ui/icons/ExploreOutlined";
import TimezoneIcon from "@material-ui/icons/AccessTime";
import DiceIcon from "@material-ui/icons/CasinoOutlined";
import AsciiIcon from "@material-ui/icons/TableChartOutlined";
import ToolIcon from "@material-ui/icons/BuildOutlined";
import BinaryIcon from "@material-ui/icons/Sync";
import UnixIcon from "@material-ui/icons/Today";
//import ConverterIcon from "@material-ui/icons/CompareArrows";

const commonPages = [
    { url: "/", name: "home", page: () => import("./HomeScreen"), icon: ToolIcon, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/imprint/", name: "bubble", page: () => import("./imprint/Imprint"), icon: ImprintIcon, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/privacy/", name: "privacy", page: () => import("./imprint/Privacy"), icon: PrivacyIcon, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/feedback/", name: "feedback", page: () => import("./imprint/Feedback"), icon: FeedbackIcon, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
];

const converterPages = [
    ...commonPages,
    //{ url: "/converter/", name: "converter", page: () => import("./converter/Converter"), category: ["converter"], icon: ConverterIcon, tool: true, lang: ["en"], lastChange: "2020-10-05" } /* prettier-ignore */,
];

const defaultPages = [
    ...commonPages,
    { url: "/ascii/", name: "ascii", page: () => import("./ascii/Ascii"), category: ["converter"], icon: AsciiIcon, tool: true, lang: ["en", "de"], lastChange: "2020-10-05" } /* prettier-ignore */,
    { url: "/unix/", name: "unix", page: () => import("./unix/Unix"), category: ["converter", "time"], icon: UnixIcon, tool: true, lang: ["en"], lastChange: "2020-07-27" } /* prettier-ignore */,
    //{ url: "/converter/", name: "converter", page: () => import("./converter/Converter"), category: ["converter"], icon: ConverterIcon, tool: true, lang: ["en"], lastChange: "2020-10-05" } /* prettier-ignore */,
    { url: "/binary/", name: "binary", page: () => import("./binary/BinaryConverter"), category: ["converter"], icon: BinaryIcon, tool: true, lang: ["en"], lastChange: "2020-07-24" } /* prettier-ignore */,
    { url: "/time-zone/", name: "timezone", page: () => import("./timezone/TimeZone"), category: ["converter", "time"], icon: TimezoneIcon, tool: true, lang: ["en", "de"], lastChange: " 2020-07-19" } /* prettier-ignore */,
    { url: "/dice/", name: "dice", page: () => import("./dice/DiceRoller"), category: ["converter"], icon: DiceIcon, tool: true, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/ruler-cm/", name: "ruler", page: () => import("./ruler/RulerCm"), category: ["sensor"], icon: RulerIcon, tool: false, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/ruler-inch/", name: "ruler", page: () => import("./ruler/RulerInch"), category: ["sensor"], icon: RulerIcon, tool: true, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/bubble/", name: "bubble", page: () => import("./bubble/BubbleLevel"), category: ["sensor"], icon: BubbleLevelIcon, tool: true, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/compass/", name: "compass", page: () => import("./compass/Compass"), category: ["sensor"], icon: CompassIcon, tool: true, lang: ["en", "de"], lastChange: "2020-07-19" } /* prettier-ignore */,
    { url: "/ascii/code/", name: "ascii", page: () => import("./ascii/AsciiCode"), category: ["converter"], icon: AsciiIcon, tool: false, lang: ["en"], lastChange: "2020-08-03" } /* prettier-ignore */,
    //{ url: "/time-differnece", name: "time", page: () => import("./time/TimeCalculator"), category:["converter"], icon: ToolIcon, tool: false }, /* prettier-ignore */
    //{ url: "/nfcReader", name: "nfcreader", page: () => import("./nfc/NfcReader"), category:["converter"], icon: ToolIcon, tool: false }, /* prettier-ignore */
];

const generateRouteList = (pageList, lang) => {
    let routeList = {};
    for (let page of pageList) {
        if (page.lang.includes(lang)) {
            const url = page.url.slice(-1) === "/" && page.url !== "/" ? page.url.slice(0, -1) : page.url;
            routeList[url] = lazy(page.page);
        }
    }
    return mount(routeList);
};

const pages = isDefaultPage ? defaultPages : converterPages;
let routes = mount({
    "/": lazy(() => import("./HomeScreen")),
    "/en": generateRouteList(pages, "en"),
    "/de": generateRouteList(pages, "de"),
});

export { routes, pages };
