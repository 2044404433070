import React, { useEffect } from "react";
import { View, NotFoundBoundary } from "react-navi";
import { CssBaseline } from "@material-ui/core";
import HelmetProvider from "react-navi-helmet-async";

import Fullscreen from "react-full-screen";
import { AppbarDrawer } from "./Menu";
import { withStore, hookStore } from "./Store";
import { isBuild } from "./Util";

const App = withStore(() => {
    //let navigation = useNavigation();
    //navigation.subscribe((route) => onRouteChange(route));

    useEffect(() => {
        if (!isBuild) {
            const jssStyles = document.querySelector('#jss-server-side');
            if (jssStyles) {
                jssStyles.parentElement.removeChild(jssStyles);
            }
        }

        const [, setPromptInstall] = hookStore("promptInstall");
        const handler = (e) => {
            e.preventDefault();
            setPromptInstall(e);
        };
        window.addEventListener("beforeinstallprompt", handler);

        return () => window.removeEventListener("transitionend", handler);
    }, []);

    const [isFull, setFull] = hookStore("isFullscreen");

    function renderNotFound() {
        return (
            <div className="App-error">
                <h1>404 - Not Found</h1>
            </div>
        );
    }
    return (

        <HelmetProvider>
            <CssBaseline />
            <Fullscreen enabled={isFull} onChange={(isFull) => setFull(isFull)}>
                <NotFoundBoundary render={renderNotFound}>
                    <div>
                        <header>
                            <AppbarDrawer />
                        </header>
                        <main>
                            <View />
                        </main>
                    </div>
                </NotFoundBoundary>
            </Fullscreen>
        </HelmetProvider>

    );
});

export { App };
